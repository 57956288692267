<template>
  <div class="animated fadeIn">
    <div class="content-page">
      <h1>Инструкция использования личного кабинета</h1>
      <p><b>Цель:</b> получение информации по планируемым рейсам для назначения в них водителя и транспортных средств, и передачи информации в информационную систему ПЭК</p>
      <div class="content-page__navigation">
        <h6>Содержание</h6>
        <ol class="content-page__navigation-list">
          <li class="content-page__navigation-item">
            <a
              href="#list-item-1"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-1')"
            >Регистрация в личном кабинете (ЛК)</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-2"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-2')"
            >Раздел «Рейсы»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-3"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-3')"
            >Раздел «Профиль компании»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-4"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-4')"
            >Раздел «Доверенности»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-5"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-5')"
            >Раздел «Мои Водители»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-6"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-6')"
            >Раздел «Транспортные средства»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-7"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-7')"
            >Раздел «Контейнеры»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-8"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-8')"
            >Раздел «Уведомления»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-9"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-9')"
            >Раздел «Аукционы»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-10"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-10')"
            >Раздел «Тендеры»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-11"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-11')"
            >Поддержка пользователей</a>
          </li>
        </ol>
      </div>
      <ol>
        <li ref="list-item-1">
          <b>Регистрация в личном кабинете (ЛК)</b><br>
          Направить  заявку на подключение сотруднику поддержки личного кабинета <a href="mailto:etp_info@pecom.ru">etp_info@pecom.ru</a> указав:<br>
          Тема: Заявка на подключение ЛК<br>
          Тело письма: Наименование организации, ИНН организации, адрес электронной почты главного пользователя куда будет отправлена ссылка на подключение
          <ol>
            <li>
              На предоставленную Вами почту будет отправлена ссылка для перехода на сайт с личным кабинетом
              <ol>
                <li><b><u>Внимание: не нажимать кнопку «Зарегистрироваться</u>»,</b> иначе возникнет конфликт паролей. Эта функция для перевозчиков, не имеющих действующего договора с ПЭК.</li>
              </ol>
            </li>
            <li>
              После перехода на сайт необходимо установить пароль, после чего можно зайти в ЛК.
              <ol>
                <li>Если, по какой-то причине не удалось сохранить пароль или забыли пароль необходимо перейти по ссылке «Забыли пароль?»</li>
              </ol>
            </li>
            <li>
              Для добавления пользователей для других сотрудников Вашей компании (добавлять может только главный пользователь п 1.1.) необходимо перейти на вкладку &lt;Профиль компании&gt; - &lt;мои пользователи&gt; и добавить пользователей, на их e-mail, также придут ссылки для захода в личным кабинет.
              <img
                src="/img/pages/contractor-manual/1.jpg"
                alt=""
              >
            </li>
          </ol>
        </li>
        <li ref="list-item-2">
          <b>Раздел «Рейсы»</b> - данный раздел предназначен для просмотра рейсов и заполнения данных по планируемым рейсам (водитель, автомобиль, прицеп, контейнер)
          <ol>
            <li>
              В данный раздел попадают рейсы подверженные логистом ПЭК для выполнения.
              <ol>
                <li><b>Основной функционал – занесение перевозчиком данных по рейсу (водитель, автомобиль, прицеп, контейнер при наличии) для отправки их в систему ПЭК.</b></li>
              </ol>
            </li>
            <li>
              Краткий бизнес-процесс
              <ol>
                <li>В личный кабинет перевозчика поступают рейсы.</li>
                <li>
                  Перевозчик в личном кабинете видит свои будущие рейсы и может инициировать:
                  <ol>
                    <li>Заполнение данных по водителю, транспортным средствам</li>
                    <li>Отказаться от рейса</li>
                  </ol>
                </li>
                <li>После сохранения данных по рейсу в личном кабинете, информация по рейсу передаются в информационную систему ПЭК и рейс подтверждается логистами ПЭК.</li>
              </ol>
            </li>
            <li>
              При заходе на вкладку рейсы по умолчанию стоит отбор по «Плановой дате подачи рейса на первую точку погрузки» Вы можете устанавливать любые даты и время для просмотра рейсов в прошлое и будущее.
              <ol>
                <li>«Дата от» = дата текущего дня 08:00:00</li>
                <li>«Дата до» = дата следующего дня 08:00:00</li>
              </ol>
            </li>
            <li>
              Условные обозначения
              <table class="table b-table table-responsive">
                <thead>
                  <tr>
                    <th>Обозначение</th>
                    <th>Действие</th>
                    <th>Расшифровка действия</th>
                  </tr>
                </thead>
                <tbody>
                  <!--tr>
                    <td><span class="fa fa-mail-forward fa-lg text-success" /></td>
                    <td>Подать заявку на готовность к выполнению</td>
                    <td>Отправка информации по рейсу в систему ПЭК, запрещено в статусе "подтвержден"</td>
                  </tr-->
                  <tr>
                    <td><span class="fa fa-pencil fa-lg text-primary" /></td>
                    <td>Редактировать</td>
                    <td>Редактирование разрешено до статуса "Подтвержден"</td>
                  </tr>
                  <tr>
                    <td><span class="fa fa-eye fa-lg text-secondary" /></td>
                    <td>Просмотр</td>
                    <td>Разрешен просмотр при любом статусе рейса</td>
                  </tr>
                  <tr>
                    <td><span class="fa fa-close fa-lg text-danger" /></td>
                    <td>Отказаться от рейса</td>
                    <td>Отказаться от конкретного рейса можно только 1 раз</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              Фильтр по рейсам:
              <ol>
                <li>«Код рейса»  - отбор рейса по кодировки рейса, принятой в ПЭК (например 1003МВКИ)</li>
                <li>«Маршрут» – отбор рейсов по полному наименованию отделений ПЭК, через запятую (например, Москва Восток, Киров)</li>
                <li>«Дата от» - отбор  рейсов по дате от «Плановой даты подачи рейса на первую точку погрузки»</li>
                <li>«Дата до» - отбор рейсов по дате до «Плановой даты подачи рейса на первую точку погрузки»</li>
                <li>«ТС» - отбор рейсов по транспортному средству (государственному регистрационному номеру), как автомобиля, так и прицепа</li>
                <li>«Водитель» - отбор рейсов по выбранному водителю</li>
                <li>
                  «Статус» -
                  <ol>
                    <li>«Все»</li>
                    <li>«Подтвержден»   - Рейс недоступен для редактирования в личном кабинете перевозчика</li>
                    <li>«Не подтвержден»  - рейс доступен для редактирования в личном кабинете перевозчика</li>
                  </ol>
                </li>
                <li>
                  «На верификации» - отбор по рейсам, по которым требуется верификация со стороны сотрудника ПЭК. Неверифицированный рейс означает, что рейс содержит неферифицированные данные:
                  <ol>
                    <li>Неверефицированные сотрудником ПЭК доверенности</li>
                    <li>Не допущенного водителя к рейсу сотрудниками СБ ПЭК</li>
                    <li>Не допущенное ТС к рейсу сотрудниками СБ ПЭК</li>
                  </ol>
                </li>
                <li>
                  Занесение данных по рейсу:
                  <ol>
                    <li>Нажать кнопку «Редактировать»</li>
                    <li>
                      Заполнить поле «Водитель 1» - поиск путем введения первых букв фамилии водителя (если водитель не найден, смотрите в инструкции раздел <a href="/drivers">«Мои Водители»</a>).
                      <ol>
                        <li>Если по рейсу будет установлен флаг «Ускоренный», то открыт доступ для заполнения поля «Водитель 2», если в рейс два водителя одновременно выполняют рейс, то необходимо указать второго водителя</li>
                      </ol>
                    </li>
                    <li>
                      После выбора водителя отобразятся все доверенности, имеющиеся у водителя
                      <ol>
                        <li>Если нет доверенности или она аннулирована, Вы можете создать доверенность из рейса на одного водителя, подробнее в разделе «Доверенности»</li>
                      </ol>
                    </li>
                    <li>Заполнить поле «Автомобиль» - поиск Автомобиля по номеру ГРН (государственный регистрационный номер)</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-3">
          <b>Раздел «Профиль компании»</b>
          <ol>
            <li>
              Необходимо заполнить данный раздел Общие данные, в дальнейшем эти данные будут использоваться для Вашего построения рейтинга
              <img
                src="/img/pages/contractor-manual/2.jpg"
                alt=""
              >
            </li>
          </ol>
        </li>
        <li ref="list-item-4">
          <b>Раздел «Доверенности»</b> – данный раздел предназначен для заведения новых доверенностей и просмотра доверенностей по Вашим водителям (Важно: если водитель.
          <ol>
            <li>
              Создание Новых доверенностей
              <ol>
                <li>Нажать кнопку добавить</li>
                <li>Заполнить все поля и прикрепить скан доверенности на водителя(лей) с печатью</li>
                <li>Одна доверенность может быть на неограниченное кол-во водителей</li>
                <li>Доверенность можно завести, только на того водителя, который есть в системе (это либо уже ранее ездивший водитель в ПЭК, либо новый водитель, которого вы можете создать в разделе &lt;Мои Водители&gt;)</li>
                <li>
                  В данном разделе уже могут быть занесены данные по доверенностям на водителей, которые находятся в статусах:
                  <ol>
                    <li>«Водитель на верификации Блокировка СБ</li>
                    <li>«Водитель не допущен Блокировка СБ»</li>
                    <li>«Водитель допущен»</li>
                  </ol>
                </li>
                <li>
                  После создания новой доверенности, она в обязательном порядке будет проходит верификацию сотрудниками ПЭК, т.е. сразу после создания статус доверенности будет «на рассмотрении».
                  <ol>
                    <li>После успешного рассмотрения будет статус «Действует»</li>
                    <li>Если доверенность не пройдет верификацию «Аннулирована» (причина скан документа не соответствует данным заведенным вами по доверенности в системе)</li>
                  </ol>
                </li>
                <li>После создания доверенности происходит их автоматическая связка с водителями, указанными в доверенности.</li>
              </ol>
            </li>
            <li>Содержаться, как действующие доверенности, так и закончившие срок своего действия</li>
            <li>
              Для поиска доверенностей можно использовать фильтры:
              <ol>
                <li>Номер доверенности</li>
                <li>Дата От</li>
                <li>Дата До</li>
                <li>Дата (дата создания)</li>
                <li>Водитель</li>
                <li>
                  Статус
                  <img
                    src="/img/pages/contractor-manual/3.jpg"
                    alt=""
                  >
                </li>
              </ol>
            </li>
            <li>Доверенность на водителя можно добавить из рейса, после выбора его в рейсе, кнопка «Добавить доверенность»</li>
            <li>
              Если у Вас есть потребность создать одну доверенность на одного водителя, удобно это сделать через раздел «Мои Водители» или через рейс, после выбора водителя в рейсе:
              <ol>
                <li>В разделе «Мои Водители» найти необходимого водителя, открыть его и в разделе доверенность нажать кнопку &lt;Добавить доверенность&gt;</li>
                <li>В разделе рейсы, после назначения водителя на рейс, необходимо нажать кнопку «Добавить доверенность»</li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-5">
          <b>Раздел «Мои Водители»</b> – в данном разделе список всех ваших водителей со статусами, также раздел предназначен для заведения новых водителей, которые еще не проходили проверку в ПЭК
          <ol>
            <li>Все водители которые ранее ездили от Вашей компании в ПЭК уже имеются в личном кабинете.</li>
            <li>
              Для поиска Водителя необходимо воспользоваться фильтром:
              <ol>
                <li>ФИО</li>
                <li>
                  Допуск
                  <ol>
                    <li>Все</li>
                    <li>Допущены к рейсам</li>
                    <li>На проверке</li>
                    <li>Не допущен</li>
                    <li>Отменен</li>
                  </ol>
                </li>
                <li>
                  Доверенность
                  <ol>
                    <li>Все</li>
                    <li>Действует</li>
                    <li>Не действует</li>
                    <img
                      src="/img/pages/contractor-manual/4.jpg"
                      alt=""
                    >
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Водители могут иметь статусы:
              <ol>
                <li>«Водитель Заблокирован СБ» - водитель еще не проходил проверку Службы Безопасности ПЭК для осуществления рейса. Водитель создан, но еще не отправлялся Вами на верификацию.</li>
                <li>«Водитель на верификации Блокировка СБ» -водитель не допущен к рейсам и отправлен Вами на верификацию(проверку СБ)</li>
                <li>«Водитель не допущен Блокировка СБ» - водитель не допущен к рейсам.</li>
                <li>«Водитель допущен» - водитель допущен для осуществления рейса (при выборе такого водителя в рейсе, данные по рейсу можно отправить в информационную систему ПЭК»</li>
              </ol>
            </li>
            <li>
              Создание нового водителя
              <ol>
                <li><b>Потребность в создании нового водителя</b> - для назначения водителя в рейс с инициированием предварительной проверки водителя в Службе Безопасности ПЭК.</li>
                <li><b>Категории водителей:</b>
                  <ol>
                    <li><u>Никогда не проходившие Службу Безопасности в ПЭК</u> – после создания нового водителя будет инициирован процесс предварительной проверке водителя, после предварительной проверки будет оповещено о допуске к окончательной проверке с личным визитом водителя в Службу Безопасности ПЭК</li>
                    <li><u>Проходившие ранее Службу Безопасности в ПЭК</u> – после создания нового водителя, произойдет связка водителя с Вашей организацией</li>
                  </ol>
                </li>
                <li>Создание Нового водителя (водителя которого нет у Вас в Личном кабинете, не имеет значение проходил ли он ранее СБ в ПЭК)
                  <ol>
                    <li>Переходим в раздел <b>«Мои ресурсы»</b> - <b>«Водители»</b> и Нажимаем кнопку <b>«Добавить»</b>
                      <img
                        src="/img/pages/contractor-manual/48.jpg"
                        alt=""
                      >
                    </li>
                    <li>Заполняете все поля водителя:
                      <ol>
                        <li>Тип паспорта</li>
                        <li>Серия паспорта</li>
                        <li>Номер паспорта</li>
                        <li>Дату Выдачи паспорта</li>
                        <li>Кем Выдан паспорт</li>
                        <li>Фамилия</li>
                        <li>Имя</li>
                        <li>Отчество (если есть)</li>
                        <li>Крепим главную страницу паспорта</li>
                        <li>Крепим страницу паспорта с пропиской</li>
                        <li>Крепим все  документы договорных отношений между Вашей организаций и водителем</li>
                        <li>Номер водительского удостоверения</li>
                        <li>Крепим скан водительского удостоверения (лицевая + обратная сторона)</li>
                        <li>Добавляем телефоны водителя (минимум 1 телефон)</li>
                        <li>Выбираем допуск Водителя
                          <ol>
                            <li>Допуск Авто = Допуск к автоперевозке(дает автоматический доступ к остальным допускам допуск к ЖД перевозке + допуск по услуге грузопровод)</li>
                            <li>Допуск Контейнер = Допуск к ЖД перевозки (доставка контейнера от ПЭК до Жд станции), не дает допуск к магистральным авторейсам</li>
                            <li>Допуск грузопровод = допуск по услуге грузопровод . не дает допуск к магистральным авторейсам</li>
                          </ol>
                        </li>
                        <li>Нажать кнопку «Создать»
                          <img
                            src="/img/pages/contractor-manual/49.jpg"
                            alt=""
                          >
                        </li>
                        <li>После создания водитель приобретает статус «Новый», можно отфильтровать в окне Допуск
                          <img
                            src="/img/pages/contractor-manual/50.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>Для инициирования начала проверки водителя, <b>ОБЯЗАТЕЛЬНО НЕОБХОДИМО ОТПРАВИТЬ ВОДИТЕЛЯ НА ПРОВЕРКУ</b>, нажать кнопку «Отправить на проверку»
                      <img
                        src="/img/pages/contractor-manual/51.jpg"
                        alt=""
                      >
                    </li>
                    <li>После отправки водителя на проверку он приобретает статус <b>«На проверке логистом»</b>
                      <img
                        src="/img/pages/contractor-manual/52.jpg"
                        alt=""
                      >
                    </li>
                    <li>Далее Логист ПЭК производит сверку введенных данных с приложенными сканами и производит проверку, если действительно есть заявка на данного водителя. После проверки всех данных и проверки заявки логист принимает решение:
                      <ol>
                        <li>Отправляет на проверку водителя в службу безопасности ПЭК, статус <b>«На верификации»</b></li>
                        <li>Возвращает обратно Вам на редактирование, снова статус <b>«Новый»</b> - в случае расхождения данных между сканами и введенной информацией.</li>
                        <li>Отклоняет, статус <b>«Отклонен»</b>, если в ПЭК нет заявки  в рейс на данного водителя</li>
                      </ol>
                    </li>
                    <li>Если Водитель отправлен на проверку СБ, то после проверки в СБ ПЭК вы увидите смену статусов <b>«Допущен»</b> или <b>«Не допущен»</b>
                      <ol>
                        <li>«Допущен» - водитель доступен для выбора в рейсе (статус приобретается только после личного визита водителя в СБ ПЭК для допуска Авто – магистральные автоперевозки)</li>
                        <li>«Не допущен» - водитель недоступен для выбора в рейсе (статус может быть установлен,  как по результат предварительной проверки СБ, так и после личного посещения водителем СБ, даже если водитель прошел предварительную проверку).</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-6">
          <b>Раздел «Транспортные средства»</b> - в данном разделе список всех ваших транспортных средств со статусами, также раздел предназначен для заведения новых транспортных средств, которые еще не проходили проверку в ПЭК
          <ol>
            <li>Все транспортные средства, которые ранее ездили от Вашей компании в ПЭК уже имеются в личном кабинете.</li>
            <li>
              Для поиска Водителя необходимо воспользоваться фильтром:
              <ol>
                <li>ГРН(Государственный Регистрационный Номер»</li>
                <li>
                  Допуск
                  <ol>
                    <li>Все</li>
                    <li>Допущен к рейсам</li>
                    <li>На проверке</li>
                    <li>Не допущен</li>
                    <li>Отменен</li>
                  </ol>
                </li>
                <li>
                  Тип
                  <ol>
                    <li>Все</li>
                    <li>Тягач</li>
                    <li>Прицеп</li>
                    <li>Полуприцеп</li>
                    <li>
                      Одиночный автомобиль
                      <img
                        src="/img/pages/contractor-manual/6.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Транспортные средства могут иметь статусы:
              <ol>
                <li>«Транспортное средство заблокировано СБ» - ТС еще не проходил проверку Службы Безопасности ПЭК для осуществления рейса. ТС создано, но еще не отправлялся Вами на верификацию.</li>
                <li>«Транспортное средство на верификации» - ТС не допущен к рейсам и отправлен Вами на верификацию (проверку СБ)</li>
                <li>«Транспортное средство допущено» - ТС допущено для осуществления рейса (при выборе такого ТС в рейсе, данные по рейсу можно отправить в информационную систему ПЭК»</li>
                <li>«Транспортное средство не допущено» -  водитель не допущен к рейсам.</li>
              </ol>
            </li>
            <li>
              Создание нового ТС:
              <img
                src="/img/pages/contractor-manual/7.jpg"
                alt=""
              >
              <ol>
                <li>Нажать кнопку «Добавить»</li>
                <li>Ввести Серию и номер свидетельства о регистрации автомобиля(СТС), строго без пробелов</li>
                <li>Прикрепить скан СТС (лицевая + обратная сторона)</li>
                <li>
                  Ввести номер Государственного регистрационного номера (ГРН) – по умолчанию маска Российского ГРН (А001АА777, А001АА77, АА0001777, АА000177)
                  <ol>
                    <li>Если у Вас ТС с иностранным ГРН, то установите флаг «Иностранный и тогда вы сможете вводить любое значение ГРН</li>
                  </ol>
                </li>
                <li>
                  Выбрать марку ТС
                  <ol>
                    <li>Необходимо вводить первый символы, как указано в СТС. Будут подобраны подходящие варианты)</li>
                    <li>Если походящих вариантов не найдено необходимо обратится к логисту ПЭК для заведения новой марки в системе</li>
                    <li>По марке транспорта автоматически будет определён тип ТС</li>
                    <li>Если у Вас ТС - платформа на которой стоит контейнер, то необходимо выбрать марку с признаком контейнеровоз, при использовании такого ТС в рейсы вы сможете прикрепить к такому ТС контейнер  </li>
                  </ol>
                </li>
                <li>Если ТС, в собственности вашей Компании, ИП, то необходимо поставить галку «Собственное ТС» (Название собственник в СТС совпадает с названием компании либо «лизингополучатель» название вашей компании)</li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-7">
          <b>Раздел «Контейнеры»</b> – в данном разделе список всех ваших контейнером, которое ранее использовались в рейсах, также раздел предназначен для заведения контейнеров
          <ol>
            <li>Контейнер используется для выбора его в рейсе, если выбранная марка выбранного Вами транспортного средства имеет признак - контейнеровоз</li>
            <li>
              Для поиска Контейнера необходимо воспользоваться фильтром:
              <ol>
                <li>Название</li>
                <li>
                  Тип
                  <ol>
                    <li>32.9 (20-фут стандарт)</li>
                    <li>35 – фут стандарт</li>
                    <li>27.8 (20-фут платформа flatrucks)</li>
                    <li>54.7 (40-фут платформа flatrucks)</li>
                    <li>67.5 (40-фут низкий)</li>
                    <li>76,3 (40-фут высокий)</li>
                    <li>84,1 (45-фут высокий)</li>
                    <li>88,0 (45-фут высокий)</li>
                  </ol>
                  <img
                    src="/img/pages/contractor-manual/8.jpg"
                    alt=""
                  >
                </li>
              </ol>
            </li>
            <li>
              Создание нового контейнера
              <ol>
                <li>Нажать кнопку «Добавить»</li>
                <li>
                  Ввести номер контейнера (без пробелов, латинские буквы + цифры)
                  <ol>
                    <li>Если введенный Вами номер контейнера уже есть в Базе ПЭК, то автоматически заполняться все информация по нему, для добавления себе такого контейнера в Ваш личный кабинет, необходимо нажать кнопку «создать»</li>
                  </ol>
                </li>
                <li>Выбираете тип контейнера из списка, автоматически будут загружены его весогабаритные характеристики.</li>
                <li>Нажимаете кнопку «Создать»</li>
              </ol>
              <img
                src="/img/pages/contractor-manual/9.jpg"
                alt=""
              >
            </li>
          </ol>
        </li>
        <li ref="list-item-8">
          <b>Раздел «Уведомления»</b> - предназначен для оповещения пользователя о событиях в ЭТП. Находится в правом верхнем углу и обозначен знаком «колокольчик».
          <img
            src="/img/pages/contractor-manual/10.jpg"
            alt=""
          >
          <ol>
            <li>
              Нажав значок «колокольчик», появится окно, в котором списком выведены все уведомления.
              <img
                src="/img/pages/contractor-manual/11.jpg"
                alt=""
              >
            </li>
            <li>Значок <span class="fa fa-square-o fa-lg text-primary" /> означает «Отметить как прочитанное», нажав на него, уведомление исчезнет из списка.</li>
            <li>Нажав «Очистить», все уведомления из списка исчезнут.</li>
            <li>
              Нажав на треугольник в центре под уведомлением, будет раскрыта более детальная информация по уведомлению.
              <img
                src="/img/pages/contractor-manual/12.jpg"
                alt=""
              >
            </li>
            <li>
              Нажав на само уведомление, откроется форма со всей информацией по разделу, по которому пришло уведомление.
              <img
                src="/img/pages/contractor-manual/13.jpg"
                alt=""
              >
            </li>
            <li>Уведомления отсортированы по категориям:</li>
            <li><b>Категория «Рейс»</b> - содержит уведомления об изменениях статусов рейсов, выполняемых вашей компанией, таких как «на верификации», «не подтвержден» «подтвержден», «изменен маршрут». При каком-либо изменении в рейсе, будет приходить уведомление.</li>
            <li><b>Категория «Водитель»</b> - содержит уведомления о статусах водителей вашей компании, таких как «на верификации в СБ», «допущен», «не допущен». При каком-либо изменении в статусе водителя, будет приходить уведомление.</li>
            <li><b>Категория «Аукцион»</b> - содержит уведомления об аукционах, таких как «открыт», «запущен», «закрыт», «отменён». При каком-либо изменении в статусе аукциона, будет приходить уведомление.</li>
            <li>Войти в раздел уведомления можно, выбрав строку <b>«Все уведомления»</b></li>
            <li>
              Уведомления, можно отобрать по необходимому периоду, указав даты и время начала и окончания, периода в правой части окна.
              <img
                src="/img/pages/contractor-manual/14.jpg"
                alt=""
              >
            </li>
            <li>Что бы выбрать необходимую категорию уведомлений, нужно воспользоваться фильтром <b>«Категория».</b></li>
            <li>Выбрав категорию <b>«Рейс»</b>, уведомления будут отсортированы по рейсам. В строке «Рейс» можно найти уведомления по конкретному рейсу, указав код рейса.</li>
            <li>Выбрав категорию <b>«Водитель»</b>, уведомления будут отсортированы по водителям. В строке «Водитель» можно найти уведомления по конкретному водителю, введя ФИО.</li>
            <li>Выбрав категорию <b>«ТС»</b>, уведомления будут отсортированы по тягачам и прицепам. В строке «ТС» можно найти уведомления по конкретному тягачу/прицепу указав номер тягача/прицепа или ГРН.</li>
            <li>Выбрав категорию <b>«Аукцион»</b>, уведомления будут отсортированы по событиям в аукционах.</li>
            <li><b>Настойка оповещений.</b></li>
            <li>
              Уведомления можно получать как в личном кабинете ЭТП, так и по электронной почте. Настройки оповещений можно выбрать в «Настойках пользователя», нажав на профиль вашей компании в правом верхнем углу.
              <img
                src="/img/pages/contractor-manual/15.jpg"
                alt=""
              >
            </li>
            <li>
              Вам будут предложены варианты настройки уведомлений. В которых проставив галочки, можно управлять категорией уведомлений и вариантами их получения: «на почту», «на сайте» или оба варианта одновременно.
              <img
                src="/img/pages/contractor-manual/16.jpg"
                alt=""
              >
            </li>
          </ol>
        </li>
        <li ref="list-item-9">
          <b>Раздел «Аукционы»</b>
          <ol>
            <li><b>Аукцион на рейс</b> – это планируемый рейс с указанными параметрами, по которому выставляется лот для проведения аукционов.</li>
            <li><b>Условия проведения Аукциона</b> – перевозчик должен иметь действующий договор перевозки и быть зарегистрированным пользователем на ЭТП.</li>
            <li>
              <b>Как узнать о планируемом аукционе:</b>
              <ol>
                <li>Уведомление в ЭТП</li>
                <li>Уведомление на почту</li>
                <li>Вкладка в Аукционе «Активные» на ЭТП</li>
              </ol>
            </li>
            <li>
              <b>Виды Аукциона:</b>
              <ol>
                <li>
                  Активный – Аукцион который уже начался и не закончился на настоящее время
                  <ol>
                    <li>Отложенный - Аукцион, который начнется в установленное время.</li>
                  </ol>
                </li>
                <li>
                  Завершенный – Аукцион, который был завершен:
                  <ol>
                    <li>Выигран Вами (Вы победитель)</li>
                    <li>Выигран иным участником (вы проигравший)</li>
                    <li>Вы сделали встречное предложение, но оно не подтверждено сотрудником ПЭК</li>
                  </ol>
                </li>
                <li>Утвержденный – аукцион, который Вы забрали в обработку (занесение данных по водителю, ТС)</li>
              </ol>
            </li>
            <li>
              <b>Краткая схема процесса:</b>
              <ol>
                <li>Перевозчик уведомляется о старте аукциона в ЭТП или на почту</li>
                <li>Перевозчик может участвовать в аукционе на понижение от актуальной ставки на текущее время с шагом, установленным ПЭК. При этом изначальная ставка и шаг ставки может быть либо с НДС либо без (указано в аукционе).</li>
                <li>Если по аукциону доступны встречные предложения, то перевозчик вправе указать свою встречную сумму за рейс (без связи с текущей или изначальной ставкой)</li>
                <li>После завершения Аукциона назначается победитель, с наименьшей ставкой, о чем будет уведомление в ЭТП либо на почту</li>
                <li>Если Аукцион не был разыгран, и вы направили встречное предложение, оно будет рассмотрено сотрудниками ПЭК и если ПЭК примет Ваши условия вы станете победители аукциона, о чем будете уведомлены ЭТП либо на почту</li>
                <li>Если Вы не выиграли Аукцион, то будет отображено, что Ваша ставка проиграла</li>
                <li>После выигранного аукциона, Вам необходимо подтвердить выполнение рейса.</li>
                <li>После подтверждения рейса, Ваш рейс переходит в ЭТП на вкладку «Рейсы» и Вы сможете заполнить все данные по рейсу и отправить данный в информационную систему ПЭК</li>
                <li>ПЭК оставляет за собой право менять маршрут рейса в рамках филиала первой и последней точки маршрута, если между первой и последней точкой добавляются промежуточные точки заезда, то они дополнительно оплачиваются согласно установленным по договоре перевозки либо доп. соглашением между Вами и  ОО «ПЭК»</li>
              </ol>
            </li>
            <li>
              <b>Подробная инструкция:</b>
              <ol>
                <li>
                  <b>Уведомление об Аукционе:</b>
                  <ol>
                    <li>
                      Активный Аукцион:
                      <ol>
                        <li>
                          Уведомление «Колокольчик» при клике на которое Вы сразу можете перейти в Аукцион
                          <img
                            src="/img/pages/contractor-manual/17.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Уведомление на почту  - при клике на рейс Вы сразу можете перейти в Аукцион
                      <img
                        src="/img/pages/contractor-manual/18.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          Уведомления вы можете настроить в настройках пользователя (правый верхний угол)
                          <img
                            src="/img/pages/contractor-manual/19.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      В личном кабинете ЭТП в меню «Аукционы» на вкладке «Активные»
                      <img
                        src="/img/pages/contractor-manual/20.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
                <li>
                  Аукционы, меню «Аукционы» вкладка <u><b>«Активные»</b></u> - здесь находятся Аукционы на рейсы <!--по которым...-->
                  <ol>
                    <li>
                      <b>Статусы Аукциона:</b>
                      <ol>
                        <li>
                          <b>«Прием ставок»</b> - в этом статусе Вы можете участвовать в торгах и  предлагать встречное предложение(если по аукциону ПЭКом назначена такая возможность – возможно сделать только одно встречное предложение)
                          <img
                            src="/img/pages/contractor-manual/21.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          <b>«Ожидает начало»</b> - в этом статусе Вы пока не можете участвовать в торгах и  предлагать встречное предложение, сможете это сделать после старта Аукциона, время которого указано в строке Аукциона.
                          <img
                            src="/img/pages/contractor-manual/22.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Участие в Аукционе:</b>
                      <ol>
                        <li>
                          После старта Аукциона вы можете, кликнув на аукцион (на вкладке аукцион – кнопка просмотр, кликнув на рейс в уведомлении) открыть его и участвовать в торгах:
                          <img
                            src="/img/pages/contractor-manual/23.jpg"
                            alt=""
                          >
                          <img
                            src="/img/pages/contractor-manual/24.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          Участие в торгах:
                          <ol>
                            <li>Вы можете сделать ставку только ниже последней предложенной иными участниками, либо ниже стартовой</li>
                            <li>Можете один раз на один аукцион сделать встречное предложение, если для конкретного аукциона доступна такая функция. (встречное предложение будет рассмотрено ответственными сотрудниками ПЭК и может быть принято).</li>
                          </ol>
                        </li>
                        <li>
                          Функции Аукциона:
                          <ol>
                            <li>
                              Кнопки «+» и «-» - кнопками можно делать отклонение от текущей ставки(либо начальной, если еще никто не сделал ставку) с шагом указанным в Аукционе.
                              <ol>
                                <li>Торги могут проходит как с НДС, так и Без НДС пересчет сразу отображен в аукционе)</li>
                              </ol>
                            </li>
                            <li>Кнопка «Сделать ставку» - делаете ставку после выбора новой ставки</li>
                            <li>
                              Кнопка «Сделать встречное предложение» - можете сделать встречное предложение в том числе с более высокой ставкой от начальной.
                              <img
                                src="/img/pages/contractor-manual/25.jpg"
                                alt=""
                              >
                            </li>
                            <li>
                              После того как кто-либо из участников сделает ставку, Вам будет отображена лидирующая ставка и если именно Ваша ставка лидирующая, также будем Вам отображено
                              <img
                                src="/img/pages/contractor-manual/26.jpg"
                                alt=""
                              >
                            </li>
                            <li>
                              Для того что бы сделать встречное предложение необходимо ввести сумму встречного предложения и нажать кнопку «Сделать встречное предложение»
                              <img
                                src="/img/pages/contractor-manual/27.jpg"
                                alt=""
                              >
                            </li>
                            <li>
                              Если ваша ставка оказалась лидирующей и время проведение аукциона будет закончено, то  Вы будете объявлены победителем.
                              <img
                                src="/img/pages/contractor-manual/28.jpg"
                                alt=""
                              >
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Аукционы, меню «Аукционы» вкладка «Завершенные» - здесь отображены все аукционы, в которых Вы принимали участие и по которым еще нет утверждения (т.е. либо Вы еще не подтвердили рейс, где Вы победитель, либо Сотрудник ПЭК еще не определился с Вашим встречным предложением, если оно было)
                  <ol>
                    <li>Статус <b>«Победа»</b> - Вы назначены победителем</li>
                    <li>
                      Статус <b>«Подведение итогов»</b> - не определен победитель
                      <ol>
                        <li>Вы были участником торгов, но в торгах не участвовали </li>
                        <li>Вы были участником торгов, но проиграли в торгах (будет указана Ваша последняя актуальная ставка, которая проиграла)</li>
                        <li>Вы сделали встречное предложение, но ответа пока нет от ПЭК (будет указана Ваше последнее встречное предложение)</li>
                      </ol>
                    </li>
                    <li>
                      Если по Аукциону ваш статус «Победа» то Вы можете:
                      <ol>
                        <li>
                          Отказаться от рейса кнопка «Отказаться от выигранного рейса» - тем самым Вы отказываетесь от выигранного рейса<br>
                          <u>ВНИМАНИЕ!!! Отказы от рейса будут негативно влиять на Ваш рейтинг и при низком рейтинге Вы не сможете участвовать в Аукционах.</u>
                          <img
                            src="/img/pages/contractor-manual/30.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Аукционы, меню «Аукционы» вкладка <u><b>«Утвержденные»</b></u> - отображаются утверждённые Аукционы.
                  <img
                    src="/img/pages/contractor-manual/31.jpg"
                    alt=""
                  >
                  <ol>
                    <li>
                      Этот рейс теперь отображается на вкладке «Рейсы» и Вы теперь сможете вносить данные по водителю и ТС  и отправлять данные в Информационную системы ПЭК (инструкция во вкладке «Руководство Пользователя»)
                      <img
                        src="/img/pages/contractor-manual/32.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-10">
          <b>Раздел «Тендеры»</b>
          <ol>
            <li><b>Тендер</b> – это набор плановых рейсов  по заранее установленному расписанию подачи на погрузку с указанными параметрами, по которому выставляется лот для проведения тендера.</li>
            <li><b>Условия проведения Тендера</b> – перевозчик должен иметь действующий договор перевозки и быть зарегистрированным пользователем на ЭТП </li>
            <li>
              <b>Как узнать оп планируемом тендере:</b>
              <ol>
                <li>Уведомление в ЭТП</li>
                <li>Уведомление на почту</li>
                <li>В Меню «Тендер», вкладка  «Активные» на ЭТП</li>
              </ol>
            </li>
            <li>
              <b>Виды Тендера:</b>
              <ol>
                <li>
                  Активный – Тендер, который уже начался и не закончился на настоящее время
                  <ol>
                    <li>Отложенный = Тендер, который начнется в установленное время.</li>
                    <li>Завершенный – тендер, который был завершен:</li>
                  </ol>
                </li>
                <li>
                  Завершенный – тендер, который был завершен:
                  <ol>
                    <li>Выигран Вами (Вы победитель)</li>
                    <li>Выигран иным участником (вы проигравший)</li>
                  </ol>
                </li>
                <li>Утвержденный – Тендер, который Вы забрали в обработку (согласились выполнять условия тендера)</li>
              </ol>
            </li>
            <li>
              <b>Краткая схема процесса:</b>
              <ol>
                <li>Перевозчик уведомляется о старте Тендера в ЭТП или на почту</li>
                <li>Перевозчик видит все будущие рейсы и начальную цену Тендера с указанием С НДС или без НДС и может участвовать в тендере путем подачи предложения за весь тендер.</li>
                <li>После завершения Тендера, сотрудник ПЭК назначает победителя, о чем будет уведомление в ЭТП либо на почту</li>
                <li>ПЭК оставляет за собой право менять маршрут рейса в рамках филиала первой и последней точки маршрута, если между первой и последней точкой добавляются промежуточные точки заезда, то они дополнительно оплачиваются согласно установленным по договоре перевозки либо доп. соглашением между Вами и  ООО «ПЭК»</li>
              </ol>
            </li>
            <li>
              <b>Подробная инструкция:</b>
              <ol>
                <li>
                  <b>Уведомление о Тендере</b>
                  <ol>
                    <li>
                      Активный Тендер:
                      <ol>
                        <li>
                          Уведомление «Колокольчик» при клике на которое Вы сразу можете перейти в тендер
                          <img
                            src="/img/pages/contractor-manual/33.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Уведомление на почту  - при клике на рейс Вы сразу можете перейти в Тендер
                      <img
                        src="/img/pages/contractor-manual/34.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          Уведомления вы можете настроить в настройках пользователя (правый верхний угол)
                          <img
                            src="/img/pages/contractor-manual/35.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      В личном кабинете ЭТП в меню «Тендеры» на вкладке «Активные»
                      <img
                        src="/img/pages/contractor-manual/36.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Статусы Активного Тендера:</b>
                  <ol>
                    <li>
                      <b>«Прием предложений»</b> - в этом статусе Вы можете участвовать в тендере -   предлагать Ваше предложение
                      <img
                        src="/img/pages/contractor-manual/37.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      <b>«Ожидает начало»</b> - в этом статусе Вы пока не можете участвовать в тендере, сможете это сделать после старта Тендера, время которого указано в столбце «Старт Тендера».
                      <img
                        src="/img/pages/contractor-manual/38.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      <b>Участие в Тендере:</b>
                      <ol>
                        <li>
                          После старта Тендера вы будете уведомлены на почту или в личном кабинете ЭТП (зависит от ваших настроек). По ссылке из письма на почту или из уведомления в ЭТП вы можете сразу перейти к открытому тендеру, либо на вкладке <b>«Активные»</b> тендеры нажать кнопку просмотр
                          <img
                            src="/img/pages/contractor-manual/39.jpg"
                            alt=""
                          >
                          <img
                            src="/img/pages/contractor-manual/40.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          Участие в тендере -  Для участия в тендере Вам необходимо указать сумму Вашего предложения в поле «Сделайте предложение» и нажать кнопку «Отправить предложение»
                          <ol>
                            <li>
                              Внимание, система налогообложения в которой Вы делаете предложение указана в поле «Ваше предложение» (НДС или без НДС), система автоматически пересчитывает и отображает значения, как с НДС, так и Без.
                              <img
                                src="/img/pages/contractor-manual/41.jpg"
                                alt=""
                              >
                            </li>
                            <li>После окончания срока проведения тендера, он автоматически переходит на вкладку <b>«Завершенные»</b></li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Статусы завершенного Тендера, меню «Тендеры» вкладка <b>«Завершенные»</b>
                  <ol>
                    <li>
                      Статус <b>«Подведение итогов»</b> - Тендер, в котором Вы принимали участие и по которым еще нет утверждения от сотрудника ПЭК
                      <img
                        src="/img/pages/contractor-manual/42.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Статус <b>«Победа»</b> - Тендер, в котором Вы принимали участие и Вы выбраны Победителем
                      <img
                        src="/img/pages/contractor-manual/43.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Если по Тендеру ваш статус <b>«Победа»</b> то Вы можете:
                      <ol>
                        <li>
                          Отказаться от рейсов кнопка <b>«Отказаться от выигранных рейсов»</b> - тем самым Вы отказываетесь от выигранного тендера<br>
                          <u>ВНИМАНИЕ!!! Отказы от рейсов будут негативно влиять на Ваш рейтинг и при низком рейтинге Вы не сможете участвовать в Тендерах.</u>
                          <img
                            src="/img/pages/contractor-manual/45.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <ol>
                    <li>
                      Все рейсы по  тендеру  теперь отображается на вкладке «Рейсы» и Вы теперь сможете вносить данные по водителю и ТС  и отправлять данные в Информационную системы ПЭК (инструкция во вкладке «Руководство Пользователя»)
                      <img
                        src="/img/pages/contractor-manual/47.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-11">
          Поддержка пользователей <a href="tel:849968111093999">(8-499-681-1109 доб. 3999)</a> <a href="mailto:etp_info@pecom.ru">etp_info@pecom.ru</a> или воспользоваться он-лайн чатом.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManual',
  methods: {
    scrollToItem(itemRef) {
      this.$refs[itemRef].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    },
  },
};
</script>

<style lang="scss">
  .content-page {

    img {
      display: block;
      max-width: 100%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    ol {
      list-style: none;
      counter-reset: li;
      padding-left: 0;
      @media screen and (max-width: 767px) {
        padding-left: 0;
      }

      li {
        padding-top: 55px;
      }

      ol {
        padding-left: 20px;
        @media screen and (max-width: 767px) {
          padding-left: 0;
        }

        li {
          padding-top: 0;
        }
      }
    }
    li:before {
      counter-increment: li;
      content: counters(li,".") ". ";
    }

    &__navigation {
      padding: .75rem 1.25rem;
      background-color: #f0f3f5;
      border: 1px solid #c8ced3;
      margin-bottom: 0;

      &-list {
        margin-bottom: 0;
      }
      &-item {
        padding-top: 0 !important;
      }
      &-link {
        color: #20a8d8;

        &:hover {
          color: #167495;
          text-decoration: underline;
        }
      }
    }
  }
</style>
